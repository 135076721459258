import React from "react"
import InnerHeader from "../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import * as CSRStyles from "./csrstyles.module.scss"

const CsrSustainability = ({ pageContext, location }) => {
  const pageTitle = "CSR Sustainability"

  return (
    <Layout>
      <Seo title={pageTitle} />
      <InnerHeader
        pageTitle={pageTitle}
        pageContext={pageContext}
        location={location}
      />
      <div className="inner-container-alt inner-y-padding">
        <p style={{ maxWidth: `70rem` }}>
          We believe a critical component of our success in the marketplace has
          been a result of our continued commitment to product innovation. Our
          consumers demand high quality, durable products that incorporate the
          highest level of comfort and the most advanced technical features and
          designs. We have a dedicated group of product design and development
          professionals, including well recognized experts in the footwear and
          apparel industries, who continually interact with consumers to better
          understand their needs and are committed to ensuring our products are
          responsibly constructed and reflect the most advanced designs,
          features and materials available in the marketplace.
        </p>
        <p style={{ maxWidth: `70rem` }}>
          Read more about our program, practices, and initiatives in our report.
        </p>
        <p style={{ maxWidth: `70rem` }}>
          <a class={`CTAblack ${CSRStyles.shortLink}`} href="/docs/csr-sustainability/rockybrands_csr_sustainability_2023_report.pdf" download>
            2023 E<span>nvironmental, </span>S<span>ocial, and </span>G<span>overnance</span> Report 
          </a>
        </p>
        <div style={{ marginTop: `3rem`, marginBottom: `3rem` }}>
          <StaticImage
            src="../../assets/images/about-us/csr-sustainibility/Muck_farm.jpg"
            alt="Family on a cow farm, walking a dirt path" />
        </div>
      </div>
    </Layout>
  )
}

export default CsrSustainability
